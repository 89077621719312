'use strict';

/*eslint-disable-next-line no-unused-vars*/
class HelperLenghtCheck {
  /**
     *
     */
  constructor() {
    this.init();
  }

  /**
     *
     */
  init() {
    // eslint-disable-next-line no-undef
    this.checkLenght();
  }

  /**
     *
     */
  checkLenght(v1, v2, options) {
    if (v1.length>v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  }
}

'use strict';

/* exported FixedOnScroll */
class FixedOnScroll {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns {{fixedHeader: string}}
   */
  default() {
    return {
      fixedHeader: 'header',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$fixedHeader = document.querySelector(this.params.fixedHeader);
    this.$wrapper = this.$elm.parentNode;

    if (window.ssm.isActive('mobile')) this.$elm.dataset.dataNoScroll = 'true';

    this.fixedElm();
    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    window.ssm.getState('mobile').options.onEnter.push(this.resetFixedElm.bind(this));
    window.ssm.getState('mobile').options.onLeave.push(this.enableFixedElm.bind(this));
    window.ssm.getState('resizeable').options.onResize.push(this.fixedElm.bind(this));

    window.addEventListener('scroll', this.fixedElm.bind(this));
  }

  /**
   *
   */
  fixedElm() {
    if (this.$elm.dataset.dataNoScroll === 'true') return;

    let bottomOffset = this.$wrapper.offsetTop + this.$wrapper.offsetHeight - this.$elm.offsetHeight;
    let windowOffset = window.pageYOffset + this.$fixedHeader.offsetHeight;

    let left = parseInt(window.getComputedStyle(this.$wrapper).getPropertyValue('padding-left'));
    let right = parseInt(window.getComputedStyle(this.$wrapper).getPropertyValue('padding-right'));
    let wrapperPadding = left + right;

    if (windowOffset > bottomOffset) {
      this.$elm.style.position = 'absolute';
      this.$elm.style.top = `${this.$wrapper.offsetHeight - this.$elm.offsetHeight}px`;
      this.$elm.style.width = `${this.$wrapper.clientWidth - wrapperPadding}px`;
    } else if (windowOffset > this.$wrapper.offsetTop) {
      this.$elm.classList.add('fixed');
      this.$elm.style.top = `${this.$fixedHeader.offsetHeight}px`;
      this.$elm.style.position = 'fixed';
      this.$elm.style.width = `${this.$wrapper.clientWidth - wrapperPadding}px`;
    } else {
      this.$elm.classList.remove('fixed');
      this.$elm.style.position = '';
      this.$elm.style.width = '';
      this.$elm.style.top = '';
    }
  }

  /**
   *
   */
  enableFixedElm() {
    this.$elm.dataset.dataNoScroll = '';
  }

  /**
   *
   */
  resetFixedElm() {
    this.$elm.dataset.dataNoScroll = 'true';
    this.$elm.classList.remove('fixed');
    this.$elm.style.position = '';
    this.$elm.style.width = '';
    this.$elm.style.top = '';
  }
}

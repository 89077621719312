'use strict';

/*eslint no-undef: "off"*/
/*eslint no-console: "off"*/
/*eslint no-unused: "off"*/
let App = {
  moduleSelector: 'data-module',
  modules: {
    ScrollTo: ScrollTo,
    Navigation: Navigation,
    MainSlider: MainSlider,
    LogoSlider: LogoSlider,
    AnimateIcon: AnimateIcon,
    GoogleLocation: GoogleLocation,
    FixedOnScroll: FixedOnScroll,
    Slider: Slider,
    EqualHeight: EqualHeight,
    TopBanner: TopBanner,
  },
  helperModules: {
    HelperSimpleStateManager: HelperSimpleStateManager,
    CookieBanner: CookieBanner,
    GoogleMapsIsLoadedCallback: () => {
      if (typeof window.google !== 'object') {
        return;
      }

      let event;
      event = new Event('gmaploaded');
      document.dispatchEvent(event);
    },
  },
  instanceCollection: {},
};

new ModuleLoader(App);


'use strict';

/* exported ScrollTo */
class ScrollTo {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns array
   */
  default() {
    return {
      elmTargetSelector: 'body',
      duration: 300,
      backToTop: 'false',
      backToTopEnableAfter: 400,
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$target = document.querySelector(this.params.elmTargetSelector) || window;

    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    this.$elm.addEventListener('click', this.scrollTo.bind(this));

    if (this.params.backToTop === 'true') {
      window.addEventListener('scroll', this.toggleBackToTopOnScroll.bind(this));
    }
  }

  /**
   *
   */
  scrollTo() {
    window.animateScrollTo(this.$target.offsetTop, {
      speed: this.params.duration,
      minDuration: 250,
      maxDuration: 1500,
      element: window,
      cancelOnUserAction: false,
    });
  }

  /**
   *
   */
  toggleBackToTopOnScroll() {
    if (window.pageYOffset > this.params.backToTopEnableAfter) {
      this.$elm.style.opacity = '1';
    } else {
      this.$elm.style.opacity = '';
    }
  }
}

'use strict';

/* exported MainSlider */
class MainSlider {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns array
   */
  default() {
    return {
      enableBullets: 'false',
      enableArrows: 'false',
      slidesContainer: '.swiper-wrapper',
      slideContainerSelector: '.slide-container',
      slideContentSelector: '.slide-content',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$slides = this.$elm.querySelector(this.params.slidesContainer).children;

    /* global Swiper */
    new Swiper(this.$elm, {
      speed: 600,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.swiper-arrow-next',
        prevEl: '.swiper-arrow-prev',
      },
      loop: false,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    this.$swiper = this.$elm.swiper;

    this.showSlideContent();
    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    this.$swiper.on('slideChangeTransitionEnd', this.showSlideContent.bind(this));

    this.$swiper.on('slideChange', this.hideSlideContent.bind(this));

    //window.addEventListener('scroll', this.backgroundImageParallax.bind(this));
  }

  /**
   *
   */
  backgroundImageParallax() {
    document.querySelectorAll(this.params.slideContainerSelector).forEach(function(slide) {
      slide.querySelector('.slide-bg-image').style.transform = 'translate3d(0,' + window.scrollY / 2 + 'px,0)';
    });
  }

  /**
   *
   */
  showSlideContent() {
    const slideContent = this.getActiveSlide().querySelector(this.params.slideContentSelector);

    slideContent.classList.add(`${this.params.slideContentSelector.replace('.', '')}--show`);
  }

  /**
   *
   */
  hideSlideContent() {
    const slideContent = this.getActiveSlide().querySelector(this.params.slideContentSelector);

    slideContent.classList.remove(`${this.params.slideContentSelector.replace('.', '')}--show`);
  }

  /**
   *
   * @returns {*}
   */
  getActiveSlide() {
    let i;
    let length = this.$slides.length;

    for (i = 0; i < length; i++) {
      if (this.$slides[i].classList.contains('swiper-slide-active')) {
        return this.$swiper.slides[i];
      }
    }
  }
}

'use strict';
/* eslint no-console: off */

/* exported TopBanner */
class TopBanner {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   *
   * @returns array
   */
  default() {
    return {
      elmSelector: '.top-banner',
      bannerButtonSelector:'.arrow',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }
    console.log('Banner working');
    this.$banner = document.querySelector(this.params.elmSelector);
    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    this.$banner.addEventListener('click', this.toggleBanner.bind(this));
  }

  /**
   *
   * @param ev
   */
  toggleBanner(ev) {
    ev.preventDefault();
    ev.currentTarget.classList.toggle('open');
  }
}

'use strict';

/* exported AnimateIcon */
class AnimateIcon {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;

    this.init();
  }

  /**
   *
   * @returns {{remainInActiveState: string, svgWrapperSelector: string}}
   */
  default() {
    return {
      svgWrapperSelector: '.animate-icon-wrapper',
      remainInActiveState: 'no', // off or yes accepted
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$svg = this.$elm.querySelector('svg');
    this.isActive = false;
    this.isAnimating = false;

    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    window.addEventListener('scroll', this.triggerElmAnimationOnScroll.bind(this));

    if (this.params.remainInActiveState === 'yes') {
      this.$elm.addEventListener('mouseenter', this.activateAnimation.bind(this));
      this.$elm.addEventListener('touchstart', this.activateAnimation.bind(this), { passive: true });
    }
  }

  /**
   *
   */
  triggerElmAnimationOnScroll() {
    let elScrollPosition = this.$elm.offsetParent.offsetTop + this.$svg.scrollHeight;
    let windowBottom = window.pageYOffset + window.innerHeight;

    if (!this.isActive && windowBottom >= elScrollPosition) {
      this.$elm.classList.add(`${this.params.svgWrapperSelector.replace('.', '')}--visible`);
      this.isActive = true;

      setTimeout(() => {
        this.$svg.classList.add('active');
      }, 1000);

      setTimeout(() => {
        if (this.params.remainInActiveState !== 'no') return;
        this.$svg.classList.remove('active');
      }, 4000);
    }
  }

  /**
   *
   * @param ev
   */
  activateAnimation(ev) {
    ev.preventDefault();

    if (this.isAnimating || !this.isActive) {
      this.isAnimating = true;
      return;
    }

    if (this.$svg.classList.contains('active')) {
      this.isAnimating = true;
      this.$svg.classList.remove('active');

      setTimeout(() => {
        this.$svg.classList.add('active');
      }, 50);

      setTimeout(() => {
        this.isAnimating = false;
      }, 1100);
    }
  }
}

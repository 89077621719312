'use strict';

/* exported CookieBanner */
class CookieBanner {
  /**
   *
   */
  constructor() {
    this.init();
  }

  /**
   *
   */
  init() {
    this.checkCookie();
  }

  /**
   *
   */
  checkCookie() {
    if (document.cookie.indexOf('COOKIES_ACCEPTED') === -1) {
      let cookie_container = document.getElementById('cookie_notice_container');

      if (cookie_container) {
        cookie_container.style.display = 'block';
      }

      // Bind click-accept-event to accept-button
      document.getElementById('cookie_notice_button_accept').addEventListener('click', () => {
        let date = new Date();
        // Set cookie
        date.setTime(date.getTime() + (1095 * 24 * 60 * 60 * 1000 + 30));

        document.cookie = 'COOKIES_ACCEPTED=1;expires=' + date.toGMTString() + ';path=/';

        // Hide notice
        if (cookie_container) {
          cookie_container.style.display = 'none';
        }

        return false;
      });
    }
  }
}

'use strict';

/* exported LogoSlider */
class LogoSlider {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns array
   */
  default() {
    return {
      enableBullets: 'false',
      enableArrows: 'false',
      slidesContainer: '.swiper-wrapper',
      slideContainerSelector: '.logo-slide-container',
      slideContentSelector: '.slide-content',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    /* global Swiper */
    new Swiper(this.$elm, {
      speed: 600,
      spaceBetween: 0,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 2,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.swiper-arrow-next',
        prevEl: '.swiper-arrow-prev',
      },
      loop: true,
      slidesPerView: 2,
      centeredSlides: false,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    this.$swiper = this.$elm.swiper;

    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
  }
}

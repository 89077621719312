'use strict';

/* exported EqualHeight */
class EqualHeight {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns {{equalSelector: string}}
   */
  default() {
    return {
      equalSelector: '> div',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$children = this.$elm.querySelectorAll(this.params.equalSelector);

    this.bindEvents();
    this.equalize();
  }

  /**
   *
   */
  bindEvents() {
    window.ssm.getState('resizeable').options.onResize.push(this.equalize.bind(this));
  }

  /**
   *
   */
  equalize() {
    let height = 0;

    this.$children.forEach(child => {
      child.style.height = '';
      if (height < child.offsetHeight) height = child.offsetHeight;
    });

    this.$children.forEach(child => {
      child.style.height = `${height}px`;
    });
  }
}

'use strict';

/* exported GoogleLocation */
class GoogleLocation {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns array
   */
  default() {
    return {
      apiKey: 'AIzaSyDXZEvFFha2AMfkEHJcDISD0bwWimxlDjQ',
      libSrc: 'https://maps.googleapis.com/maps/api/js',
      map: {
        lat: 50.942460,
        lng: 6.955440,
      },
      iconImage: '/assets/svg/location2.svg',
      routeCta: 'Hierher navigieren',
      routeHref:
        'https://www.google.com/maps/place/Fix-Smartphone.de%C2%AE+%2F+Smartphone,+iPhone+Reparatur+K%C3%B6ln/@50.9423749,6.955505,15z/data=!4m5!3m4!1s0x0:0xcdf2dcdb33a43519!8m2!3d50.9423749!4d6.955505',
      infoWindowContent: 'Fix-Smartphone.de<br />An den Dominikanern 3<br />50668 Köln',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.animatedMarker = false;
    this.loadGoogleLibrary();
  }

  /**
   *
   */
  loadGoogleLibrary() {
    let lib = document.createElement('script');
    let libSrc;

    libSrc = `${this.params.libSrc}?key=${this.params.apiKey}&callback=App.helperModules.GoogleMapsIsLoadedCallback`;

    lib.setAttribute('async', '');
    lib.setAttribute('defer', '');
    lib.setAttribute('src', libSrc);
    lib.setAttribute('type', 'text/javascript');

    document.querySelector('body').appendChild(lib);

    document.addEventListener('gmaploaded', () => {
      this.createGoogleMap();
    });
  }

  /**
   *
   */
  createGoogleMap() {
    const styledMapType = new window.google.maps.StyledMapType([
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#282828',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#282828',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#7c7c7c',
          },
        ],
      },
      {
        featureType: 'landscape',
        stylers: [
          {
            color: '#000000',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#305949',
          },
        ],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.government',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.medical',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#305949',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#254a3b',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#507957',
          },
        ],
      },
      {
        featureType: 'poi.place_of_worship',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.school',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.sports_complex',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#505050',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#b3b3b3',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#282828',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#6e6e6e',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#333333',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            lightness: 30,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
          {
            lightness: 30,
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#505050',
          },
          {
            weight: 1,
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#333333',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#d59563',
          },
        ],
      },
      {
        featureType: 'transit.station.airport',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#333333',
          },
        ],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#333333',
          },
        ],
      },
      {
        featureType: 'transit.station.rail',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#333333',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#005c90',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ]);

    this.$gMap = new window.google.maps.Map(this.$elm, {
      zoom: 13,
      center: this.params.map,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    });

    this.$gMap.mapTypes.set('styled_map', styledMapType);
    this.$gMap.setMapTypeId('styled_map');

    this.$gInfowindow = new window.google.maps.InfoWindow({
      content: `<p>
                  ${this.params.infoWindowContent}
                </p>
                <p>
                  <a href="${this.params.routeHref}" class="button button-primary" target="_blank">
                    ${this.params.routeCta}
                  </a>
                </p>`,
    });

    this.animateMarker();
    window.addEventListener('scroll', this.animateMarker.bind(this));
  }

  /**
   *
   */
  animateMarker() {
    if (this.animatedMarker) return;

    let elScrollPosition = this.$elm.offsetTop + this.$elm.scrollHeight * 0.75;
    let windowBottom = window.pageYOffset + window.innerHeight;

    if (windowBottom >= elScrollPosition) {
      this.$gMapMarker = new window.google.maps.Marker({
        position: this.params.map,
        map: this.$gMap,
        animation: 2,
        icon: this.params.iconImage,
      });

      this.animatedMarker = true;

      this.$gMapMarker.addListener('click', () => {
        this.$gInfowindow.open(this.$gMap, this.$gMapMarker);
      });
    }
  }
}

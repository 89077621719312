'use strict';

/*eslint-disable-next-line no-unused-vars*/
class HelperSimpleStateManager {
  /**
   *
   */
  constructor() {
    this.init();
  }

  /**
   *
   */
  init() {
    this.addStates();
  }

  /**
   *
   */
  addStates() {
    window.ssm.addState({
      id: 'resizeable',
      query: '(min-width: 320px)',
    });

    window.ssm.addState({
      id: 'mobile',
      query: '(max-width: 767px)',
    });

    window.ssm.addState({
      id: 'sm',
      query: '(min-width: 576px) and (max-width: 767px)',
    });

    window.ssm.addState({
      id: 'md',
      query: '(min-width: 768px) and (max-width: 991px)',
    });

    window.ssm.addState({
      id: 'lg',
      query: '(min-width: 992px) and (max-width: 1999px)',
    });

    window.ssm.addState({
      id: 'xl',
      query: '(min-width: 1200px)',
    });
  }
}

'use strict';

/* exported Slider */
class Slider {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   * Object of data-parameters
   * elmSelector is written as data-elm-selector=".module"
   *
   * @returns array
   */
  default() {
    return {
      enableBullets: 'true',
      enableArrows: 'true',
      slidesWrapperSelector: 'swiper-wrapper',
      slidesSelector: '.swiper-slide',
      sliderMobileOnly: 'true',
      slideArrowsVisibleClass: 'd-md-block',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    this.$slides = this.$elm.querySelectorAll(this.params.slidesSelector);

    if (this.params.sliderMobileOnly === 'false') {
      this.buildSlider();
    } else if (this.params.sliderMobileOnly === 'true' && window.ssm.isActive('mobile')) {
      this.buildSlider();
    }

    this.bindEvents();
  }

  /**
   *
   */
  buildSlider() {
    this.$slideWrapper = document.createElement('div');
    this.$slideWrapper.classList.add(this.params.slidesWrapperSelector);

    this.$elm.insertBefore(this.$slideWrapper, this.$slides[0]);
    this.$slides.forEach(slide => {
      this.$slideWrapper.appendChild(slide);
    });

    this.$elm.classList.add('swiper-container');

    /*eslint-disable-next-line no-undef*/
    new Swiper(this.$elm, {
      speed: 600,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.swiper-arrow-next',
        prevEl: '.swiper-arrow-prev',
      },
      loop: false,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });

    this.$swiper = this.$elm.swiper;

    if (this.params.sliderMobileOnly === 'false') {
      this.$swiper.navigation.nextEl.classList.add(this.params.slideArrowsVisibleClass);
      this.$swiper.navigation.prevEl.classList.add(this.params.slideArrowsVisibleClass);
    }
  }

  /**
   *
   */
  destroySwiper() {
    this.$swiper.destroy();
    this.$elm.classList.remove('swiper-container');

    this.$slides.forEach(slide => {
      this.$elm.appendChild(slide);
      this.$slideWrapper.remove();
    });
  }

  /**
   *
   */
  bindEvents() {
    if (this.params.sliderMobileOnly === 'true') {
      window.ssm.getState('mobile').options.onEnter.push(this.buildSlider.bind(this));
      window.ssm.getState('mobile').options.onLeave.push(this.destroySwiper.bind(this));
    }
  }
}

'use strict';
/* eslint no-console: off */

/* exported Navigation */
class Navigation {
  /**
   *
   * @param elm
   * @param params
   */
  constructor(elm, params) {
    this.params = params;
    this.$elm = elm;
    this.init();
  }

  /**
   *
   * @returns array
   */
  default() {
    return {
      elmSelector: '.navigation',
      navSelector: '.nav-container',
      itemSelector: '.item',
      headerSelector: 'header',
      htmlSelector: 'html',
      closeIconSelector: '.menu-toggle',
      mainContentSelector: '.main-content',
      parentSelector: 'li',
    };
  }

  /**
   *
   */
  init() {
    if (this.$elm === undefined) {
      return;
    }

    document.querySelector(this.params.elmSelector).classList.add('loaded');
    this.$header = document.querySelector(this.params.headerSelector);
    this.$headerFixedPlaceholder = this.$header.parentElement.querySelector(
      `.${this.params.headerSelector}--placeholder`
    );
    this.$buttonClose = this.$header.querySelector(this.params.closeIconSelector);
    this.$wrapper = document.querySelector(this.params.mainContentSelector);
    this.$html = document.querySelector(this.params.htmlSelector);
    this.$navi = document.querySelector(this.params.navSelector);
    this.$item = this.$navi.querySelectorAll(this.params.itemSelector);
    this.fixedNav();
    this.bindEvents();
  }

  /**
   *
   */
  bindEvents() {
    this.$buttonClose.addEventListener('click', this.toggleNav.bind(this));
    this.$item.forEach(item => {
      item.addEventListener('click', this.openItem.bind(this));
    });
    window.addEventListener('scroll', this.fixedNav.bind(this));
  }

  /**
   *
   */
  openNav(ev) {
    this.navL = ev.path[0];
    if(this.navL.classList.contains('show')){
      this.navL.classList.remove('show');
    } else {
      this.navL.classList.add('show');
    }
  }

  /**
   *
   * @param ev
   */
  toggleNav(ev) {
    ev.preventDefault();
    ev.currentTarget.classList.toggle(`${this.params.closeIconSelector.replace('.', '')}--opened`);
    document.body.classList.toggle('opened-navigation');

    this.preventScroll();
  }

  /**
   *
   * @param ev
   */
  openItem(ev) {
    console.log('open', ev);
    ev.target.nextElementSibling.classList.toggle('show');
    ev.target.firstElementChild.classList.toggle('collapsed');
    ev.preventDefault();
  }

  /**
   *
   */
  preventScroll() {
    let width = this.$html.getBoundingClientRect().width;

    if (document.body.classList.contains('opened-navigation')) {
      this.$html.style.position = 'relative';
      this.$html.style.maxWidth = width + 'px';
      this.$header.style.maxWidth = width + 'px';
    } else {
      setTimeout(() => {
        this.$html.style.position = '';
        this.$html.style.maxWidth = '';
        this.$html.style.overflow = '';
        this.$header.style.maxWidth = '';
      }, 600);
    }
  }

  /**
   *
   */
  fixedNav() {
    if (this.$wrapper.children.length === 0) return;

    if (window.pageYOffset > this.$header.offsetHeight) {
      this.$header.classList.add(`${this.params.headerSelector}--fixed`);
      this.$headerFixedPlaceholder.style.display = 'block';
    } else {
      this.$header.classList.remove(`${this.params.headerSelector}--fixed`);
      this.$headerFixedPlaceholder.style.display = '';
    }

    if (window.pageYOffset > 220) {
      this.$header.classList.add(`${this.params.headerSelector}--animated`);
      this.$header.classList.add(`${this.params.headerSelector}--animated-shadow`);
    } else {
      this.$header.classList.remove(`${this.params.headerSelector}--animated`);
      this.$header.classList.remove(`${this.params.headerSelector}--animated-shadow`);
    }
  }
}

'use strict';

/* exported ModuleLoader */
class ModuleLoader {
  /**
   *
   * @param app
   */
  constructor(app) {
    this.app = app;
    this.init();
  }

  /**
   *
   */
  init() {
    this.loadHelperModules();
    window.addEventListener('load', this.loadModules.bind(this));
  }

  /**
   *
   */
  loadHelperModules() {
    for (let key in this.app.helperModules) {
      if (this.app.helperModules.hasOwnProperty(key)) {
        new this.app.helperModules[key]();
      }
    }
  }

  /**
   *
   */
  loadModules() {
    for (let key in this.app.modules) {
      if (this.app.modules.hasOwnProperty(key)) {
        let elms = document.querySelectorAll(`[${this.app.moduleSelector + '=' + key.toLowerCase()}]`);
        if (elms.length > 0) {
          this.initEachModule(elms, key);
        }
      }
    }
  }

  /**
   *
   * @param elms
   * @param module
   */
  initEachModule(elms, module) {
    elms.forEach(elm => {
      if (elm.dataset.moduleinit !== 'true') {
        elm.dataset.moduleinit = 'true';
        this.app.instanceCollection[module] = [];
        let params = this.overwriteDefaultProps(new this.app.modules[module]().default(), elm.dataset);
        this.app.instanceCollection[module].push(new this.app.modules[module](elm, params));
      }
    });
  }

  /**
   *
   * @param _default
   * @param dataset
   */
  overwriteDefaultProps(_default, dataset) {
    let params = {};

    for (let value in _default) {
      if (_default.hasOwnProperty(value)) {
        if (dataset[value] !== undefined && dataset[value] !== _default[value]) {
          params[value] = dataset[value];
        } else {
          params[value] = _default[value];
        }
      }
    }
    return params;
  }
}
